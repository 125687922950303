<template>
  <v-navigation-drawer id="core-navigation-drawer" v-model="drawer" 
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    color="gradient-drawer"
    app
    width="230"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    
    <v-img src="@/assets/logo_login.png" max-height="50" class="img-menu"/>
    <v-divider class="mb-2" />
    <span class="font-weight-bold red-txt text-center">Menú</span>
    <v-list dense nav>
        <v-list-item
          v-for="item in items_sinoep"
          :key="item.title"
          :to="item.link">
          
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
    </v-list>

  </v-navigation-drawer>
</template>

<script>
  // Utilities
   
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({

      items_sinoep: [
          { active:true,
            title: 'Comprobantes',
            icon: 'mdi-file-find',
            name:'Comprobantes',
            link: '/panel/pages/comprobantes',
          },
          { active:true,
            title: 'Préstamos', 
            icon: 'mdi-file-settings',
            name:'Préstamos',
            link: '/panel/pages/prestamos',
          },
          // { active:true,
          //   title: 'Expedientes', 
          //   icon: 'mdi-file-document-multiple',
          //   name:'Expediente',
          //   link: '/panel/pages/expediente', 
          // },
        ],


      historialComprobantes:{
        title: 'Historial Comprobantes',
        icon: 'mdi-clipboard-file-outline',
        to: {name:'historial-comprobantes'}
      },
      items: [
        {
          active:true,
          icon: 'mdi-view-dashboard',
          title: 'Inicio',
          group:"/panel",
          children: [
            {
              active:true,
              icon: 'fa fa-file',
              title: 'Comprobantes',
              name:'Comprobantes',
              to: 'pages/comprobantes',
            },
            {
              active:true,
              icon: 'fa fa-file',
              title: 'Préstamos',
              name:'Préstamos',
              to: 'pages/prestamos',
            },
            {
              active:true,
              icon: 'fa fa-file',
              title: 'Expediente',
              name:'Expediente',
              to: 'pages/expediente',
            },
            // {
            //   title: 'rtables',
            //   icon: 'mdi-clipboard-outline',
            //   to: '',
            // },
            // {
            //   title: 'typography',
            //   icon: 'mdi-format-font',
            //   to: '/panel/components/typography',
            // },
          ]
        },
       
       
        // {
        //   title: 'google',
        //   icon: 'mdi-map-marker',
        //   to: '/maps/google-maps',
        // },
        // {
        //   title: 'notifications',
        //   icon: 'mdi-bell',
        //   to: '/components/notifications',
        // },
        // {
        //   title: 'notifications',
        //   icon: 'mdi-bell',
        //   to: '/components/notifications',
        // },
        // {
        //   title: 'notifications',
        //   icon: 'mdi-bell',
        //   to: '/components/notifications',
        // },
        // {
        //   title: 'notifications',
        //   icon: 'mdi-bell',
        //   to: '/components/notifications',
        // },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage','activeHistorialComprobantes']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: true,
          title: this.$t(''),
        }
      },
    },
    mounted(){
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      async logout(){

        let token = localStorage.getItem('token');
        let form = new FormData();
        form.append("token",token.split(" ")[1]);
        await this.$http.post("logout",form);
        localStorage.removeItem('token');

        this.$store.commit("setAutenticated",false);
        this.$router.push({name:"Login"});
      }
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
